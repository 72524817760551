<template>
  <div class="page-box">
    <div class="article-title">{{ title }}</div>
    <div class="article">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleDetail",
  data() {
    return {
      title: "", //文章标题
      content: "", // 文章内容
      type: "", //菜单类型（1：判决案例、2：调解纠纷、3：新闻动态、4：讼诉服务）
      id: "", //文章ID
    };
  },
  created() {
    // console.log(this.$route.params.type);
    // console.log(this.$route.params.id);
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    // console.log(this.id)
    if (this.type == "" || this.id == "") return;
    this.getArticle(this.type, this.id);
  },
  methods: {
    async getArticle(type, id) {
      const { data: res } = await this.$http.get("news/detail", {
        params: { type: type, id: id },
      });
      if (res.meta.status !== 200) {
        return this.$message.error("内容获取失败！");
      }
      this.content = res.data.content;
      this.title = res.data.title;
    },
  },
};
</script>

<style scoped>
.page-box {
  width: 1200px;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.article-title {
  font-size: 32px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #464646;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.article {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #464646;
  margin-bottom: 50px;
  line-height: 36px;
  text-indent: 2em;
  font-size: 18px;
  padding: 0px 60px 0px 60px;
}
</style>