<template>
  <div class="webheader">
    <div class="header-box">
      <div class="header-left">
        <img src="@/assets/fayuan-logo.png" alt="" />
        <div class="logo1-txt">中江县人民法院特约调解组织</div>
      </div>
      <div class="header-right">
        <div class="center-box">
          <img src="@/assets/center-logo.png" alt="" />
          <div>
            <div class="logo-chinese">明净商事调解中心</div>
            <div class="logo-letter">MINGJING COMMER CIAL MEDIATION CENTER</div>
          </div>
        </div>
        <div class="search-box">
          <input type="text" placeholder="请输入您想要查询的案件" />
          <div class="search-btn" @click="searCourt">
            <img src="@/assets/search.png" alt="" />
          </div>
        </div>
        <!-- <div class="date-box">2023-10-28 星期四</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebHeader",
  methods: {
    searCourt() {
    //   this.$alert("法院接口对接中...", "提示框", {
    //     confirmButtonText: "确定",
    //     callback: (action) => {
    //       console.log(action);
    //     },
    //   });
    this.$router.push("/query")
    },
  },
};
</script>

<style scoped>
.webheader {
  /* border: 1px solid red; */
  height: 193px;
  background-image: url("../assets/header-bg.png");
  background-size: 100% 100%;
}
.header-box {
  box-sizing: border-box;
  display: flex;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  /* border: 1px solid red; */
  align-items: center;
}
.header-left {
  /* border: 1px solid red; */
  width: 590px;
  margin-left: 187px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-left img {
  width: 60px;
  height: 60px;
}
.logo1-txt {
  font-size: 38px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00548d;
  letter-spacing: 1px;
}
.header-right {
  /* border: 1px solid red; */
  width: 330px;
  margin-left: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 20px;
}
.center-box {
  display: flex;
}
.center-box img {
  width: 34px;
  height: 46px;
}
.logo-chinese {
  font-size: 26px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #00548d;
}
.logo-letter {
  font-size: 8px;
  font-family: Segoe UI-Semilight, Segoe UI;
  font-weight: normal;
  color: #00548d;
  letter-spacing: 1px;
}
.search-box {
  width: 330px;
  height: 40px;
  background: #ffffff;
  border-radius: 29px 29px 29px 29px;
  opacity: 1;
  position: relative;
  margin-top: 10px;
}
.search-box input {
  margin-left: 20px;
  height: 40px;
  width: 200px;
  border: none;
  /* 去掉获取焦点时的边框 */
  outline: none;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
}
/* 设置输入框placehoder的字体颜色 */
.search-box input::placeholder {
  color: #bfbfbf;
}
.search-btn {
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 40px;
  background: #005dba;
  border-radius: 0px 29px 29px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-btn img {
  width: 22px;
  height: 22px;
}
.date-box {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #00548d;
  margin-top: 10px;
}
</style>