<template>
  <div class="page-box">
    <!-- <div class="article-title">调解员信息</div> -->
    <!-- 搜索栏 -->
    <el-form :inline="true" class="search-box">
      <el-form-item label="调解人员姓名:">
        <el-input
          placeholder="请输入调解人员姓名"
          v-model="queryInfo.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="调解中心线路号码:">
        <el-input
          placeholder="请输入调解中心线路号码"
          v-model="queryInfo.line_number"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryMediator">查 询</el-button>
      </el-form-item>
    </el-form>

    <div class="mediators">
      <div class="mediator" v-for="m in mediators" :key="m.id">
        <img v-if="m.pic" :src="m.pic" alt="" />
        <img v-else src="@/assets/avatar.png" alt="" />
        <div class="info">
          <span class="name">{{ m.name }}</span>
          <div>
            <img src="@/assets/phone.png" alt="" />
            <span class="phone">{{ m.line_number }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 分页 -->
    <div class="pagenation">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[12, 24]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediatorInfo",
  data() {
    return {
      //   文章列表
      mediators: [],
      // 查询参数对象
      queryInfo: {
        name: "", //调解员姓名
        line_number: "", //中心线路
        pagenum: 1,
        pagesize: 12,
      },
      // 总数据条数
      total: 0,
    };
  },
  created() {
    this.getArticlesList();
  },
  methods: {
    // 根据分页获取对应的调解员列表
    async getArticlesList() {
      const { data: res } = await this.$http.get("mediator", {
        params: this.queryInfo,
      });
      if (res.meta.status !== 200) {
        return this.$message.error("获取调解员列表失败！");
      }
      this.mediators = res.data.mediators;

      for (var m of this.mediators) {
        if (m.pic) {
        //   m.pic = `http://127.0.0.1:8088/${m.pic}`;
        m.pic = `http://47.108.231.55:8088/${m.pic}`;
        }
      }
      this.total = res.data.total;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getArticlesList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getArticlesList();
    },
    queryMediator() {
      this.getArticlesList();
    },
  },
};
</script>

<style scoped>
.page-box {
  width: 1200px;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.article-title {
  font-size: 32px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #464646;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.search-box {
  margin-top: 40px;
}
.search-box .el-input {
  width: 300px;
}
.search-box .el-form-item {
  margin-right: 20px;
}
.mediators {
  /* border: 1px solid red; */
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  /* 直接用spane-between，而无需指定中间具体的间隔值 */
  /* justify-content: space-between; */
}
.mediator {
  width: 260px;
  height: 300px;
  /* border: 1px solid red; */
  position: relative;
  margin-bottom: 20px;
  margin-right: 40px;
}
.mediator img {
  width: 100%;
  height: 100%;
}
.info {
  width: 260px;
  height: 60px;
  /* background: #000000;
  opacity: 0.48; */
  background-color: rgba(0, 0, 0, 0.48);
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.info .name {
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-top: 8px;
}
.info img {
  width: 16px;
  height: 16px;
}
.info .phone {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.pagenation {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
}
.page-index {
  border: 1px solid #005dba;
  border-radius: 2px;
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #005dba;
  text-align: center;
  margin: 0px 10px;
}
</style>