<template>
  <div class="page-box">
    <div class="title">
      <img src="@/assets/case.png" alt="" />
      <span>判决案例</span>
    </div>
    <div class="article-list">
      <a @click="gotoDetail(1, a.id)" v-for="a in articles" :key="a.id">
        <div class="article">
          <span class="art-title">{{ a.title }}</span>
          <span class="date">[ {{ a.add_time | dateFormat }}] </span>
        </div>
      </a>
    </div>

    <!-- <div class="pagenation">
      <img src="@/assets/arr-left.png" alt="" />
      <div class="page-index">1</div>
      <img src="@/assets/arr-right.png" alt="" />
    </div> -->

    <!-- 分页 -->
    <div class="pagenation">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "JudgmentCase",
  data() {
    return {
      //   文章列表
      articles: [],
      // 查询参数对象
      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      // 总数据条数
      total: 0,
    };
  },
  created() {
    this.getArticlesList();
  },
  methods: {
    // 根据分页获取对应的文章列表
    async getArticlesList() {
      const { data: res } = await this.$http.get("judgmentcase", {
        params: this.queryInfo,
      });

      if (res.meta.status !== 200) {
        return this.$message.error("获取文章列表失败！");
      }
      //   this.$message.success("获取文章列表成功！");
      this.articles = res.data.articles;
      this.total = res.data.total;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getArticlesList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getArticlesList();
    },
    gotoDetail(type, id) {
      this.$router.push(`/detail/${type}/${id}`);
    },
  },
};
</script>

<style scoped>
.page-box {
  width: 1200px;
  /* border: 1px solid red; */
  margin: 0 auto;
  margin-bottom: 20px;
}
.title {
  width: 1200px;
  height: 50px;
  background: #005dba;
  margin-top: 80px;
  display: flex;
  align-items: center;
}
.title img {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-right: 10px;
}
.title span {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.article-list a {
  text-decoration: none;
}
.article {
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  border-bottom: 1px dashed #e1e1e1;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.article .date {
  color: #005dba;
}
.article .art-title {
  color: #464646;
  /* 超出省略 */
  width: 1000px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pagenation {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.page-index {
  border: 1px solid #005dba;
  border-radius: 2px;
  width: 26px;
  height: 26px;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #005dba;
  text-align: center;
  margin: 0px 10px;
}
</style>