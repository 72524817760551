<template>
  <div class="page-box">
    <div class="search-box">
      <div class="search-title">案件查询</div>
      <div class="search-content">
        <div class="search-item">
          <input
            type="text"
            placeholder="请输入姓名"
            v-model="queryInfo.name"
          />
        </div>
        <div class="search-item">
          <input
            type="text"
            placeholder="请输入身份证号"
            v-model="queryInfo.id_card"
          />
        </div>
        <div class="search-item">
          <input
            type="text"
            placeholder="请输入手机号"
            v-model="queryInfo.phone"
          />
        </div>
        <div class="search-item">
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="validateCode"
            class="txt-code"
          />
          <el-button :disabled="haveSend" @click="sendCode"
            >获取验证码</el-button
          >
        </div>
        <div class="search-item">
          <button @click="queryCase" class="searchBtn">查询案件信息</button>
        </div>
      </div>
    </div>

    <!-- 案件列表 对话框-->
    <el-dialog
      title="案件列表"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <span v-if="total === 0">没有查询到案件信息</span>
      <div v-else>
        <!-- 案件列表 -->
        <el-table border stripe :data="caseList">
          <el-table-column type="index"></el-table-column>
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="身份证号码" prop="id_card"></el-table-column>
          <el-table-column
            label="合同编号"
            prop="contract_no"
          ></el-table-column>
          <el-table-column
            label="合同编号"
            prop="borrow_platform_name"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="mediateInfo(scope.row)"
                >打 开</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>

    <!-- 调解书 -->
    <el-dialog
      title="调解书"
      :visible.sync="dialogCaseInfoVisible"
      width="80%"
      :before-close="handleCaseInfoClose"
    >
      <div class="book-container">
        <div class="book-title">中江明净商事调解中心</div>
        <div class="book-title">风险告知书</div>
        <div class="boot-no">
          （2023）明净调<span>{{ currentCase.mediate_no }}</span
          >号
        </div>
        <div class="user_name">
          <span>{{ currentCase.name }}</span> ：
        </div>
        <div class="notice">
          你方与 <span>{{ currentCase.borrow_platform_name }}</span>
          借款纠纷一案，中江明净商事调解中心现受委托进行调解，向你方告知如下：
        </div>
        <div class="notice">
          1、你方于 <span>{{ currentCase.borrow_date }}</span
          >通过 <span>{{ currentCase.borrow_platform_name }}</span> （平台）
          借款￥ <span>{{ currentCase.borrow_total }}</span> 元，借款合同编号为
          <span>{{ currentCase.contract_no }}</span>
          。因你方到期未偿还该笔借款，该笔借款现已逾期，逾期金额￥
          <span>{{ currentCase.overdue_amount }}</span> 元。
        </div>
        <div class="notice">
          2、你方可以于收到该风险告知书之日起7日内自行偿还该笔借款本息或与
          <span>{{ currentCase.borrow_platform_name }}</span> 达成调解协议。
        </div>
        <div class="notice">
          3、如你方不能自行偿还借款本息也不接受调解，本案将进入诉讼程序，相关法律后果由你方自行承担。
        </div>
        <div class="notice">
          4、<span>{{ currentCase.borrow_platform_name }}</span>
          指定对公还款账户：
        </div>
        <div class="notice">
          户 名：<span>{{ currentCase.borrow_platform_get_name }}</span>
        </div>
        <div class="notice">
          开户银行：<span>{{ currentCase.borrow_platform_get_bank }}</span>
        </div>
        <div class="notice">
          银行账号：<span>{{ currentCase.borrow_platform_get_account }}</span>
        </div>
        <div class="notice">特此通知。</div>
        <div class="notice-date">
          <span>{{ currentCase.add_date }}</span>
        </div>
        <div class="gz">
          <img src="../assets/gz.png" alt="" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="needMediate(false)">不需要调解</el-button>
        <el-button type="primary" @click="needMediate(true)"
          >需要调解</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CaseQuery",
  data() {
    return {
      dialogVisible: false, //案件列表对话框
      dialogCaseInfoVisible: false, //调解书对话框
      // 查询参数对象
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
        name: "", //姓名
        id_card: "", //身份证
        phone: "", //电话
      },
      caseList: [], //案件列表
      total: 0, // 总数据条数
      currentCase: {}, //当前查看调解书的案件
      validateCode: "", // 验证码
      haveSend: false, //是否已发送短信
    };
  },
  methods: {
    async queryCase() {
      //   网络请求
      if (this.queryInfo.name == "" || this.queryInfo.name.trim() == "") {
        this.$message("请输入用户名");
        return;
      }
      if (this.queryInfo.id_card == "" || this.queryInfo.id_card.trim() == "") {
        this.$message("请输入身份证");
        return;
      }
      if (this.queryInfo.phone == "" || this.queryInfo.phone.trim() == "") {
        this.$message("请输入电话");
        return;
      }
      if (this.validateCode == "" || this.validateCode.trim() == "") {
        this.$message("请输入验证码");
        return;
      }
      //验证码校验
      this.getCodeBtn();
      const { data: resCode } = await this.$http.post("sms/getcode", {
        phone: this.queryInfo.phone,
      });
      if (resCode.meta.status !== 201) {
        // console.log("获取验证码失败！");
        return this.$message.error("获取验证码失败！");
      } else {
        // console.log("获取验证码成功！");
        if (resCode.data !== this.validateCode) {
          return this.$message.error("验证码不正确！");
        }
      }
      // 验证码通过
      const { data: res } = await this.$http.get("cases", {
        params: this.queryInfo,
      });
      if (res.meta.status !== 200) {
        return this.$message.error("获取案件列表失败！");
      }
      //   console.log(res)
      //   网络请求没有失败，但是没查到数据
      if (res.data.cases.length > 0) {
        // return this.$message.error("获取案件列表成功！");
        this.caseList = res.data.cases;
        this.total = res.data.total;
      } else {
        // return this.$message.info("未查询到案件信息！");
      }
      this.dialogVisible = true; //显示查询框
    },
    handleClose(done) {
      done();
    },
    handleCaseInfoClose(done) {
      // 即使在调解书上没有点击确认或取消按钮，只要点击右上角的关闭或者点击其它地方调解书消失，都会记录
      // 也就是，只要打开了一次调解书，就会记录一次
      this.needMediate(false);
      done();
    },
    mediateInfo(data) {
      // console.log(data);
      //   this.currentCase = this.caseList[index];
      this.currentCase = data;
      //显示调解书
      this.dialogCaseInfoVisible = true;
    },
    // 需要或不需要调解
    async needMediate(need) {
      if (need) {
        this.dialogCaseInfoVisible = false;
        this.$message.error("申请提交成功，平台24小时内专业调解员与您联系！");
      } else {
        this.dialogCaseInfoVisible = false;
      }

      //   查询记录入库
      const { data: res } = await this.$http.post("casesquery", {
        name: this.currentCase.name,
        id_card: this.currentCase.id_card,
        phone: this.currentCase.phone,
        contract_no: this.currentCase.contract_no,
        agree_mediate: need,
      });
      console.log(res);
      if (res.meta.status !== 201) {
        console.log("查询记录入库失败！");
      } else {
        console.log("查询记录入库成功！");
      }
    },
    // 获取验证码按钮处理
    getCodeBtn() {
      this.haveSend = true;
      setTimeout(() => {
        this.haveSend = false;
      }, 60000); //1分钟后可以点击
    },
    //发送验证码
    async sendCode() {
      const { data: resCode } = await this.$http.post("sms", {
        phone: this.queryInfo.phone,
      });
      if (resCode.meta.status !== 201) {
        return this.$message.error("发送验证码失败！");
      } else {
        return this.$message("发送验证码成功！");
      }
    },
  },
};
</script>

<style scoped>
.page-box {
  width: 1200px;
  /* border: 1px solid red; */
  /* height: 200px; */
  margin: 0 auto;
  padding-top: 81px;
}
.search-box {
  width: 581px;
  /* height: 381px; */
  padding-bottom: 30px;
  /* border: 1px solid blue; */
  margin: 0 auto;
  margin-bottom: 81px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.07);
}
.search-title {
  width: 581px;
  height: 70px;
  background: #e4e9fb;
  /* opacity: 0.14; */
  font-size: 20px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3f65e2;
  text-align: center;
  vertical-align: middle;
  line-height: 70px;
}
.search-content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.search-item {
  margin-top: 20px;
}
.search-item input {
  width: 400px;
  height: 40px;
  border: 1px solid #e1e1e1;
  border-radius: 4px 4px 4px 4px;
  /* 去掉获取焦点时的边框 */
  outline: none;
  padding-left: 20px;
}
/* 设置输入框placehoder的字体颜色 */
.search-item input::placeholder {
  color: #bfbfbf;
}
.search-item .txt-code {
  width: 280px;
  margin-right: 10px;
}
.search-item .searchBtn {
  width: 400px;
  height: 50px;
  background: #005dba;
  border-radius: 4px 4px 4px 4px;
  color: #fff;
}
/* 调解书 */
.book-container {
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  padding-left: 40px;
  font-size: 18px;
}
.book-title {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.boot-no {
  display: flex;
  justify-content: right;
}
.user_name {
  font-size: 20px;
  font-weight: bold;
}
.notice {
  text-indent: 36px; /*首行缩进 */
  line-height: 32px;
}
.notice-date {
  display: flex;
  justify-content: right;
}
.book-container span {
  text-decoration: underline;
  color: #000;
  margin: 0px 10px;
}
/* 公章 */
.gz {
  width: 155px;
  height: 155px;
  position: absolute;
  right: 80px;
  bottom: 120px;
}
.gz img {
  width: 155px;
  height: 155px;
}
</style>