<template>
  <div>
    <!-- 幻灯片 -->
    <div class="top-box">
      <div class="top-container">
        <Swiper :options="options" ref="swiper">
          <SwiperSlide class="swiper-box" v-for="item in 3" :key="item">
            <img src="@/assets/banner.png" alt="" />
            <div class="swiper-title">
              最高人民法院承办代表建议、政协提案交办会最高人民法院承办代表建议、政协提案...
            </div>
          </SwiperSlide>
          <!-- 分页器 -->
          <div slot="pagination" class="swiper-pagination"></div>
        </Swiper>
        <div class="query-box">
          <div class="box-header-query">中 江 县</div>
          <div class="get-case">已诉调对接案件（件）</div>
          <div class="box-number">
            <span>5</span><span>0</span><span>0</span><span>5</span
            ><span>5</span>
          </div>
          <div class="case-query" @click="goToSearch">案 件 查 询</div>
          <div class="case-query" @click="complaintss">监 督 投 诉</div>
        </div>
      </div>
    </div>
    <!-- 案件统计 -->
    <div class="case-count-box">
      <div class="count-box">
        <img src="@/assets/mediate-count.png" alt="" />
        <div class="case-count">{{ animatedMediateCase }}</div>
        <div class="case-type">调解案件</div>
      </div>
      <div class="count-box">
        <img src="@/assets/case-count.png" alt="" />
        <div class="case-count">{{ animatedRegisterCase }}</div>
        <div class="case-type">立案案件</div>
      </div>
      <div class="count-box">
        <img src="@/assets/judgment-count.png" alt="" />
        <div class="case-count">{{ animatedJudgmentCase }}</div>
        <div class="case-type">判决案件</div>
      </div>
    </div>

    <!-- 关于我们 -->
    <div class="box-header">
      <div class="header-txt">关于我们</div>
      <img src="@/assets/header-line.png" alt="" />
    </div>
    <!-- <div class="aboutus">
       <div class="aboutus-content">
        <p>
          中江县明净商事纠纷调解中心成立于2023年10月16日，注册地位于四川省德阳市中江县一环路北段951-985号(中江凯美商贸综合楼商业3层)，法定代表人为刘纯富。经营范围包括开展金融纠纷调解、商事纠纷调解;调解研究，法律咨询。
        </p>
      </div> 
    </div> -->
    <div class="hdp2">
      <Swiper :options="options2" ref="swiper">
        <SwiperSlide class="swiper-box">
          <img src="@/assets/hdp4.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide class="swiper-box">
          <img src="@/assets/hdp5.jpg" alt="" />
        </SwiperSlide>
        <SwiperSlide class="swiper-box">
          <img src="@/assets/hdp6.jpg" alt="" />
        </SwiperSlide>
        <!-- 分页器 -->
        <div slot="pagination" class="swiper-pagination"></div>
      </Swiper>
    </div>
    <!-- 金融纠纷调解流程 -->
    <div class="box-header">
      <div class="header-txt">金融纠纷调解流程</div>
      <img src="@/assets/header-line.png" alt="" />
    </div>
    <div class="dispute-flow">
      <img src="@/assets/dispute-flow.png" alt="" />
    </div>

    <!-- 金融纠纷审判流程 -->
    <div class="box-header">
      <div class="header-txt">金融纠纷审判流程</div>
      <img src="@/assets/header-line.png" alt="" />
    </div>
    <div class="judgment-flow">
      <img src="@/assets/judgment-flow.png" alt="" />
    </div>

    <!-- 金融纠纷强制执行流程 -->
    <div class="box-header">
      <div class="header-txt">金融纠纷强制执行流程</div>
      <img src="@/assets/header-line.png" alt="" />
    </div>
    <div class="execute-flow">
      <img src="@/assets/execute-flow.png" alt="" />
    </div>

    <!-- 文章列表 -->
    <div class="article-box">
      <!-- 调解纠纷指南 -->
      <div class="service">
        <div class="service-header">
          <div>
            <div class="service-tips">调解纠纷指南</div>
            <a href="#" class="service-more">查看更多</a>
            <img src="@/assets/more.png" alt="" />
          </div>
          <img src="@/assets/service-icon.png" alt="" />
        </div>
        <div class="service-list">
          <a href="#">
            <div class="service-item">
              <div class="service-title">诉讼服务</div>
              <div class="service-date">2013-10-24</div>
            </div>
          </a>
          <a href="#">
            <div class="service-item">
              <div class="service-title">诉讼服务</div>
              <div class="service-date">2013-10-24</div>
            </div>
          </a>
          <a href="#">
            <div class="service-item">
              <div class="service-title">诉讼服务</div>
              <div class="service-date">2013-10-24</div>
            </div>
          </a>
        </div>
      </div>

      <!-- 讼诉服务指南 -->
      <div class="mediate">
        <div class="mediate-header">
          <div>
            <div class="service-tips">诉讼服务指南</div>
            <a href="#" class="service-more">查看更多</a>
            <img src="@/assets/more.png" alt="" />
          </div>
          <img src="@/assets/service-icon.png" alt="" />
        </div>
        <div class="service-list">
          <a href="#">
            <div class="service-item">
              <div class="service-title">纠纷调解</div>
              <div class="service-date">2013-10-24</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- 监督投诉弹出层 -->
    <el-dialog :visible.sync="dialogVisble" width="50%" @close="dialogClosed">
      <el-form
        ref="complaintFormRef"
        :model="complaintForm"
        :rules="complaintFormRules"
        label-width="80px"
      >
        <el-form-item label="投诉内容">
          <el-input v-model="complaintForm.content"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisble = false">取 消</el-button>
        <el-button type="primary" @click="doComplaint">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "Index",
  data() {
    return {
      // options配置项: 使用swiper官网的配置项即可
      options: {
        //自动轮播图
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //无缝衔接
        loop: true,
        //分页器配置项
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
        //切换效果
        effect: "cube",
      },
      //   关于我们幻灯片
      options2: {
        //自动轮播图
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //无缝衔接
        loop: true,
        //分页器配置项
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 点击分页器小球进行轮播图切换
        },
        //切换效果
        // effect: "coverflow",
        slidesPerView: 3,
        centeredSlides: true,
      },
      mediateCase: 0, //调解案件
      registerCase: 0, //立案案件
      judgmentCase: 0, //判决案件
      mediateCaseCount: 0, //最新调解案件数（递增时包含小数，所以要用计算属性处理）
      registerCaseCount: 0, //最新立案案件数
      judgmentCaseCount: 0, //最新判决案件数
      dialogVisble: false, //投诉弹出框
      complaintForm: {
        //投诉内容
        content: "",
      },
      //   投诉框表单验证规则
      complaintFormRules: {
        content: [
          { required: true, message: "请输入投诉内容", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    //鼠标进入-暂停
    this.$refs.swiper.$el.onmouseenter = () => {
      this.$refs.swiper.$swiper.autoplay.stop();
    };
    //鼠标离开-开始
    this.$refs.swiper.$el.onmouseleave = () => {
      this.$refs.swiper.$swiper.autoplay.start();
    };

    //  案件统计数
    this.mediateCase = 500358;
    this.registerCase = 13368;
    this.judgmentCase = 2985;
  },
  watch: {
    mediateCase: function (newValue) {
      gsap.to(this.$data, { duration: 5, mediateCaseCount: newValue });
    },
    registerCase: function (newValue) {
      gsap.to(this.$data, { duration: 5, registerCaseCount: newValue });
    },
    judgmentCase: function (newValue) {
      gsap.to(this.$data, { duration: 5, judgmentCaseCount: newValue });
    },
  },
  computed: {
    // 调解案件数
    animatedMediateCase: function () {
      return this.mediateCaseCount.toFixed(0);
    },
    // 立案案件数
    animatedRegisterCase: function () {
      return this.registerCaseCount.toFixed(0);
    },
    // 判决案件数
    animatedJudgmentCase: function () {
      return this.judgmentCaseCount.toFixed(0);
    },
  },
  methods: {
    goToSearch() {
      this.$router.push("/query");
    },
    complaintss() {
      //   this.$alert("法院接口对接中...", "提示框", {
      //     confirmButtonText: "确定",
      //     callback: (action) => {
      //       console.log(action);
      //     },
      //   });
      this.dialogVisble = true;
    },
    doComplaint() {
      //   console.log(this.complaintForm);
      // 校验
      this.$refs.complaintFormRef.validate(async (valid) => {
        if (!valid) return;
        const { data: res } = await this.$http.post("complaint", {
          content: this.complaintForm.content,
        });
        if (res.meta.status !== 201) {
          return this.$message.error("投诉添加失败！");
        }
        // 投诉成功
        this.dialogVisble = false;
        //   上班时间：如有紧急投诉，请致电中江县人民法院0838-7131208
        //   下班时间：如有紧急投诉，请在工作时间致电中江县人民法院0838-7131208
        this.$alert(
          "如有紧急投诉，请致电中江县人民法院 0838-7131208！",
          "投诉提示框",
          {
            confirmButtonText: "确定",
            callback: (action) => {
              console.log(action);
            },
          }
        );
      });
    },
    // 投诉层关闭
    dialogClosed() {},
  },
};
</script>

<style scoped>
/* 幻灯片 */
.top-box {
  /* width: 1600px; */
  max-width: 1600px;
  height: 462px;
  max-height: 492px;
  /* border: 1px solid red; */
  background-image: url("../assets/top-bg.png");
  background-size: 100%, 100%;
  margin: 0 auto;
}
.top-container {
  width: 1200px;
  /* border: 1px solid red; */
  height: 410px;
  margin: 0 auto;
  padding-top: 22px;
  /* margin-bottom: 20px; */
  position: relative;
}
.swiper-box {
  position: relative;
}
.swiper-title {
  position: absolute;
  left: 20px;
  bottom: 21px;
  height: 104px;
  width: 480px;
  background-image: url("../assets/swiper-title-bg.png");
  background-size: 100%, 100%;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.query-box {
  position: absolute;
  right: 38px;
  top: 44px;
  width: 334px;
  height: 400px;
  background-color: #f5f9fe;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  /* border: 1px solid red; */
  padding: 0px 20px;
}
.box-header-query {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  /* border: 1px solid red; */
  width: 100%;
  background-image: url("../assets/box-header.png");
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #65a3e6;
}
.get-case {
  /* border: 1px solid red; */
  font-size: 14px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #464646;
}
.box-number {
  margin: 20px auto;
}
.box-number span {
  /* border: 1px solid red; */
  display: inline-block;
  background: linear-gradient(180deg, #5e9fe5 0%, #3d7ac1 51%, #5e9fe5 100%);
  border-radius: 7px 7px 7px 7px;
  width: 49px;
  height: 69px;
  text-align: center;
  vertical-align: middle;
  line-height: 69px;
  font-size: 60px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  margin-right: 5px;
}
.case-query {
  margin-bottom: 20px;
  /* border: 1px solid red; */
  width: 241px;
  height: 50px;
  background-image: url("../assets/case-query.png");
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  line-height: 50px;
}
/* 案件统计 */
/* 3个复用样式，只是图片不一样 */
.case-count-box {
  width: 1200px;
  /* border: 1px solid red; */
  /* height: 200px; */
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  justify-content: space-around;
}
.count-box {
  margin-top: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid green; */
  width: 280px;
  height: 249px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.07);
  border-radius: 10px 10px 10px 10px;
  position: relative;
}
.count-box img {
  position: absolute;
  top: -60px;
  left: 80px;
  width: 120px;
  height: 120px;
}
.case-count {
  padding-top: 107px;
  font-size: 45px;
  font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
  font-weight: 800;
  color: #005dba;
}
.case-type {
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #464646;
}

/* 通用标题 */
.box-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto;
}
.header-txt {
  font-size: 22px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #005dba;
  margin-bottom: 8px;
}
.box-header img {
  width: 48px;
  height: 2px;
}
/* 关于我们 */
.aboutus {
  height: 380px;
  background: url("../assets/aboutus-bg.png");
  box-shadow: 0px 1px 6px 1px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutus-content {
  width: 1200px;
  margin: 0 auto;
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 34px;
}
/* 金融纠纷调解流程 */
.dispute-flow {
  display: flex;
  justify-content: center;
}
.dispute-flow img {
  width: 1200px;
  height: 680px;
}
/* 金融纠纷审判流程 */
.judgment-flow {
  display: flex;
  justify-content: center;
}
.judgment-flow img {
  width: 1200px;
  height: 278px;
}
/* 金融纠纷强制执行流程 */
.execute-flow {
  display: flex;
  justify-content: center;
}
.execute-flow img {
  width: 1200px;
  height: 279px;
}
/* 文章列表 */
.article-box {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  /* border: 1px solid red; */
  margin: 0 auto;
  /* height: 200px; */
  margin-top: 59px;
  margin-bottom: 59px;
}
.service-header {
  width: 580px;
  height: 186px;
  border-radius: 4px 4px 0px 0px;
  background-image: url("../assets/service-bg.png");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  box-sizing: border-box;
}
.service-tips {
  font-size: 45px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 20px;
}
.service-more {
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.service-list {
  height: 256px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.07);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
}
.service-list a {
  text-decoration: none;
}
.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  padding-top: 20px;
}
.service-title {
  font-size: 18px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #464646;
  margin-left: 30px;
}
.service-date {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #328aec;
  margin-right: 30px;
}
/* 头部背景图片替换，其它复用 */
.mediate-header {
  width: 580px;
  height: 186px;
  border-radius: 4px 4px 0px 0px;
  background-image: url("../assets/mediate-bg.png");
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  box-sizing: border-box;
}
/* 关于我们幻灯片 */
.hdp2 {
    width: 1590px;
    margin: 0 auto;
    /* border:1px solid red; */
}
.hdp2 img {
  height: 300px;
  width: 450px;
}
</style>