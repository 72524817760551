<template>
  <div class="page-box">
    <div class="article-title">调解中心简介</div>
    <div class="article">
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CenterIntroduction",
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.getArticle();
  },
  methods: {
    async getArticle() {
      const { data: res } = await this.$http.get("introduce/1");
      if (res.meta.status !== 200) {
        return this.$message.error("简介内容获取失败！");
      }
      this.content = res.data.introduce;
    },
  },
};
</script>

<style scoped>
.page-box {
  width: 1200px;
  /* border: 1px solid red; */
  margin: 0 auto;
}
.article-title {
  font-size: 32px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #464646;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 30px;
}
.article {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #464646;
  margin-bottom: 50px;
  line-height: 36px;
  text-indent: 2em;
  font-size: 18px;
  padding: 0px 60px 0px 60px;
}
</style>