import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Index from '../pages/Index.vue'
import CenterIntroduction from '../pages/CenterIntroduction.vue'
import JudgmentCase from '../pages/JudgmentCase.vue'
import MediateDisputes from '../pages/MediateDisputes.vue'
import MediatorInfo from '../pages/MediatorInfo.vue'
import NewsList from '../pages/NewsList.vue'
import ProceedingService from '../pages/ProceedingService.vue'
import CaseQuery from '../pages/CaseQuery.vue'
import ArticleDetail from '../pages/ArticleDetail.vue'

const router = new VueRouter({
    routes: [{
        path: "/",
        redirect: "/index"
    }, {
        path: "/index",
        component: Index
    }, {
        path: "/intro",
        component: CenterIntroduction
    }, {
        path: "/case",
        component: JudgmentCase
    }, {
        path: "/dispute",
        component: MediateDisputes
    }, {
        path: "/mediator",
        component: MediatorInfo
    }, {
        path: "/news",
        component: NewsList
    }, {
        path: "/service",
        component: ProceedingService
    }, {
        path: "/query",
        component: CaseQuery
    }, {
        path: "/detail/:type/:id",
        component: ArticleDetail
    }]
})
router.afterEach(() => {
    document.title = '明净商事调解中心';
})
export default router
