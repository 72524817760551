import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

import "swiper/css/swiper.min.css"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);
// Swiper 代表最外侧容器
// SwiperSlide 代表每一张轮播图

Vue.config.productionTip = false

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import axios from 'axios'
// 配置请求的跟路径
// axios.defaults.baseURL = 'http://127.0.0.1:8088/frontapi/'
// axios.defaults.baseURL = 'http://47.108.231.55:8088/frontapi/'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.interceptors.request.use(config => {
    return config
})
axios.interceptors.response.use(response => {
    return response
})
Vue.prototype.$http = axios

// 格式化时间戳
Vue.filter('dateFormat', (val) => {
    // 乘以1000，防止为1970年
    const dt = new Date(val * 1000)
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')
    // const hh = (dt.getHours() + '').padStart(2, '0')
    // const mm = (dt.getMinutes() + '').padStart(2, '0')
    // const ss = (dt.getSeconds() + '').padStart(2, '0')
    //时间格式年月日、时分秒
    // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    return `${y}-${m}-${d}`
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
