<template>
  <div id="app">
    <!-- 通用顶部 -->
    <WebHeader />
    <!-- 通用菜单 -->
    <WebMenu />
    <!-- 页面内容 -->
    <div class="page-container">
      <router-view />
    </div>

    <!-- 通用底部 -->
    <WebFooter />
  </div>
</template>

<script>
import WebHeader from "./components/WebHeader.vue";
import WebMenu from "./components/WebMenu.vue";
import WebFooter from "./components/WebFooter.vue";

export default {
  name: "App",
  components: {
    WebHeader,
    WebMenu,
    WebFooter,
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#app {
}
</style>
