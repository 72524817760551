<template>
  <div class="webmenu">
    <ul class="menu-box">
      <li class="menu-item" :class="{ active: isActive('/index') }">
        <router-link to="/index">首页</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/intro') }">
        <router-link to="/intro">中心简介</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/case') }">
        <router-link to="/case">判决案例示例</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/dispute') }">
        <router-link to="/dispute">调解纠纷指南</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/mediator') }">
        <router-link to="/mediator">调解员信息</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/news') }">
        <router-link to="/news">新闻动态指南</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/service') }">
        <router-link to="/service">诉讼服务指南</router-link>
      </li>
      <li class="menu-item" :class="{ active: isActive('/query') }">
        <router-link to="/query">案件查询</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WebMenu",
  data() {
    return {};
  },
  methods: {
    isActive(path) {
      // 判断当前路由是否激活，如果是则添加类名
      return this.$route.path === path;
    },
  },
};
</script>

<style scoped>
.webmenu {
  height: 90px;
  background: #3f65e2;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.menu-box {
  width: 1200px;
  height: 90px;
  margin: 0 auto;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-item {
  width: 150px;
  height: 90px;
  box-shadow: inset 3px 0px 20px 1px #3f65e2;
  /* background: #ffffff; */
  /* opacity: 0.1; */
  /* 通过rgba解决背景透明，文字也透明的问题 */
  background-color: rgba(255, 255, 255, 0.1);
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: center;
}
a {
  text-decoration: none;
}
.menu-item a {
  /* border: 1px solid red; */
  font-size: 20px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
}
.menu-item.active {
  background: #0bd2ad;
  box-shadow: inset 3px 0px 20px 1px rgba(63, 101, 226, 0.29);
}
</style>