<template>
  <div class="webfooter">
    <div class="link-logo">
      <div class="logo-item">
        <a target="_blank" href="https://tingshen.court.gov.cn/"><img src="../assets/a1.png" alt="" /></a>
      </div>
      <div class="logo-item">
        <a target="_blank" href="https://splcgk.court.gov.cn/gzfwww/"><img src="../assets/a2.png" alt="" /></a>
      </div>
      <div class="logo-item">
        <a target="_blank" href="https://wenshu.court.gov.cn/"><img src="../assets/a3.png" alt="" /></a>
      </div>
      <div class="logo-item">
        <a target="_blank" href="http://zxgk.court.gov.cn/index.jsp"><img src="../assets/a4.png" alt="" /></a>
      </div>
      <div class="logo-item">
        <a target="_blank" href="https://songda.court.gov.cn/"><img src="../assets/a5.png" alt="" /></a>
      </div>
      <div class="logo-item">
        <a target="_blank" href="https://ssfw.court.gov.cn/ssfww/"><img src="../assets/a6.png" alt="" /></a>
      </div>
    </div>
    <div class="link-text">友情链接： <a target="_blank" href="https://www.court.gov.cn/index.html">最高人民法院</a></div>
    <div class="footer">
      <div class="footer-txt">
        Copyright @2023 中江县明净商事纠纷调解中心版权所有
      </div>
      <div class="footer-txt">备案号：<a target="_blank" href="https://beian.miit.gov.cn/">蜀ICP备2024090113号-1</a></div>
      <div class="footer-txt">地址:四川省德阳市中江县一环路北段951-985号</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebFooter",
};
</script>

<style scoped>
.webfooter {
  /* height: 268px; */
  background: #005dba;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.link-logo {
  margin: 30px 0px 10px 0px;
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
}
.logo-item {
  width: 230px;
  height: 140px;
  background: #0b6ac9;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
}
.logo-item:last-child{
    margin-right: 0px;
}
.logo-item img {
  width: 176px;
  height: 67px;
}
.link-text {
  font-size: 12px;
  font-family: Source Han Sans CN-Light, Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
  /* letter-spacing: 50px; */
  margin-bottom: 20px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  /* opacity: 0.15;
  background: #000; */
  background-color: rgba(0, 0, 0, 0.15);
}
.footer-txt {
  color: #fff;
  margin-right: 50px;
  font-size: 12px;
  font-family: Source Han Sans CN-Light, Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
}
.link-text a{
    text-decoration: none;
    color: #FFF;
}
.footer-txt a{
    text-decoration: none;
    color: #FFF;
}
</style>